import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Contact via `}<a parentName="p" {...{
        "href": "mailto:contact@ooofabrika.ru"
      }}>{`email`}</a>{` or `}<a parentName="p" {...{
        "href": "tel:+78612044782"
      }}>{`+7(861)204-47-82`}</a></p>
    <div style={{
      fontSize: '0.8rem',
      color: `#a0aec0`
    }}>
198216, Saint Petersburg, pr. Narodnogo Opolchenia, 45 A, office 1
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      